import React from 'react'
import Hero from '../components/Hero'
import Content from '../components/Content'
import SlidingCarousal from '../components/SlidingCarousal'
import Footer from '../components/Footer'

function AboutPage(props){
    return(
        <div class="parent-css">
            <Hero title={props.title} />
{/*             <Content>
                Hello, the following is the description.
                <p>Seeking a challenging position as a Software Engineer with an impressive record of achievements in</p>
                <p> designing, developing, implementing, troubleshooting and maintaining, multi-tier Web Applications.</p>
                <p> Worked through various phases of SDLC and have consistently contributed to the project's success.</p>
                <p>Takes an initiative to finish within time and cost and suggest ways for improvement.  </p>
            </Content> */}
            <SlidingCarousal />
            <Footer />
        </div>
    )
}

export default AboutPage