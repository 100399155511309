import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer(){
return(
    <footer className="footer-pos">
        <Container className="p-5">
            <Row className="border-top justify-content-between p-3">
                <Col className="p-0 d-flex" md={3} sm={12}>
                    Copyright@Sid
                </Col>
                <Col className="p-0 d-flex justify-content-end" md={3}>
                    This site is designed by Sid
                </Col>
            </Row>
        </Container>
    </footer>
)
}

export default Footer