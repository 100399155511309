import React, { Component } from 'react';
import Resume from '../documents/resume.pdf';
import Container from 'react-bootstrap/Container';

class Download extends Component {

  render() {
    return (
      <Container fluid={true}>
        <a href = {Resume} target = "_blank">Download Resume</a>
      </Container>
    );
  }
}

export default Download;