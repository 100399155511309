import React,{Component} from 'react'
import { GithubLoginButton } from 'react-social-login-buttons'
import GitHubLogin from 'react-github-login'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Facebook from './Facebook'
import Github from './Github'
import Firebase from './Firebase'

import {
    faYoutube,
    faFacebook,
    faTwitter,
    faGithub,
    faLinkedin,
    faWordpress,
    faStackOverflow,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons"



class PopUp extends Component {

    handleClick = () => {
     this.props.toggle();
    };

  

  render() {
    return (
      <div>
      <div>
        <h1>{this.props.text}</h1>
        <button onClick={this.handleClick}>close me</button>
        <div>
          <Firebase />
        </div>
         
      </div>
    </div>
    );
   }
  }

export default PopUp