import React from 'react'
import { useSpring, animated } from 'react-spring'
import SlidingCard from './SlidingCard';

function CardInfo(props){

    const style = useSpring({opacity: 1, from: {opacity: 0}});
    return(
        <div>
            <animated.div className="my-card-info" style={style}>
                <p className="my-card-title">{props.title}</p>
                <p className="my-card-subTitle">{props.subTitle}</p>
                <p>Click on the button below:</p>
                <a href={props.link} target="_blank" rel="noopener noreferrer">View</a>
            </animated.div>
        </div>
    );
}

export default CardInfo