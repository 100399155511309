import React from 'react'
import CardInfo from '../components/CardInfo'
import SlidingCard from './SlidingCard';

function Card(props){
    return(
        <div className="d-inline-block my-card" >
            <img className="my-card-image" onMouseOver={(e) => props.hover(props.item)} src={props.item.imgSrc} alt={props.item.imgSrc} />
            { props.item.selected && <CardInfo title={props.item.title} subTitle={props.item.subTitle} link={props.item.link} /> }
            { props.item.selected && <SlidingCard title={props.item.title} img={props.item.imgSrc} /> }
        </div>
    );
}

export default Card