import React, {Component} from 'react'
import firebase from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import UserHome from './UserHome'
import ContactPage from '../pages/ContactPage'
import Constant from './Constants'


firebase.initializeApp({
    apiKey: Constant.REACT_APP_FIREBASE_API_KEY,
    authDomain:'portfolio-firebase-auth.firebaseapp.com'
})

class Firebase extends Component{

    state = { isSignedIn: false }
    uiConfig = {
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID
      ],
      signInSuccessUrl: '/signedIn',
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            console.log('signInSuccessWithAuthResult', authResult, redirectUrl);
            this.props.history.push({ContactPage});
            return false
        }
      }
    }
  
    componentDidMount = () => {
      firebase.auth().onAuthStateChanged(user => {
        this.setState({ isSignedIn: !!user })
        console.log("user", user)
      })
    }

    render(){
        return (
            <div>
            {this.state.isSignedIn ? (
              <UserHome />
            ) : (
              <StyledFirebaseAuth
                uiConfig={this.uiConfig}
                firebaseAuth={firebase.auth()}
              />
            )}
          </div>
        )


    }

}

export default Firebase