import React from "react";
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import SlidingPaneContent from './SlidingPaneContent'


class SlidingCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenLeft: false,
            imgSrc: props.img,
            title: props.title,
            showImage: true
        };
        setInterval( () => {
            this.setState(previousState => {
                return {showImage: !previousState.showImage };
            })
        }, 1000);
    }



 
    componentDidMount() {
        Modal.setAppElement(this.el);
    }
 

    render() {
        return <div ref={ref => this.el = ref}> 
            <img className="sliding-pane-click-img" onClick={() => {
                this.setState({ isPaneOpen: true });
            } } src = {this.state.imgSrc} />
            <SlidingPane
                isOpen={ this.state.isPaneOpen }
                title={this.state.title}
                subtitle={this.state.title}
                onRequestClose={ () => {
                    // triggered on "<" on left top click or on outside click
                    console.log("right pane closed");
                    this.setState({ isPaneOpen: false });
                } }>
                <SlidingPaneContent title={this.state.title} />
                <br />
            </SlidingPane>
        </div>;
    }
}

export default SlidingCard;
