import React, {Component} from 'react'
import {
    BrowserRouter as Router,
    Route,
    Link
  } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import Download from './Download'
import Footer from './Footer'
import youtube from '../images/youtube.JPG'
import Button from 'react-bootstrap/Button'
import PopUp from "./PopUp"; 

class Landing extends Component{

    constructor(props){
        super(props)
        this.state = {
            title: 'Sid',
            headerLinks: [
                {title: 'Home', path: '/'},
                {title: 'About', path: '/about'},
                {title: 'Contact', path: '/contact'}
            ],
            home: {
                title: 'There are weeks where decades happen',
                subTitle: 'You had me at hello!',
                text: 'the Universe is sometimes too Lazy'
            },
            about: {
                title: 'About Me'
            },
            contact: {
                title: 'Let\'s talk'
            },
            login: {
                title: 'You have logged in'
            },
            hover: false,
            seen: false
        }      
        console.log("hover"+this.hover)
    }

    handleCoffeeMouseOver() {
        this.setState({hover: !this.state.hover})
        console.log("handleCoffeeMouseOver"+this.hover)
    }


    togglePop = () => {
        console.log("Login Button is clicked. Time to show Popup..seen state="+this.state.seen)
        this.setState({
         seen: !this.state.seen
        });
    };


    render(){
        var coffeeHover;
        if(this.state.hover){
            coffeeHover = {backgroundColor: 'red'}
        }
        return(
            <div>
                <Router>
                    <Container className="p-0" fluid={true}>
                        <Navbar className="border-bottom header-pos" bg="transparent" expand="lg">
                            <Navbar.Brand>
                                Sagacious Sid 
                                <Download/>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbar-toggle"/>
                            <Navbar.Collapse id="navbar-toggle">
                                <a style={coffeeHover} href="https://www.buymeacoffee.com/sshanka4" onMouseOver={() => this.handleCoffeeMouseOver()} target="_blank">
                                        <img src="https://cdn.buymeacoffee.com/buttons/default-green.png" responsive alt="Buy Me A Coffee" />
                                </a>
                                <Nav className="ml-auto">
                                    <div>
                                    <Button className="nav-link" onClick={this.togglePop}><strong>Login</strong></Button>
                                    {this.state.seen ? <PopUp toggle={this.togglePop} /> : null}
                                    </div>
                                    <Link className="nav-link" to="/"><strong>Home</strong></Link>
                                    <Link className="nav-link" to="/about"><strong>About</strong></Link>
                                    <Link className="nav-link" to="/contact"><strong>Contact</strong></Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        <Route path="/" exact render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text}></HomePage>} />
                        <Route path="/about" exact render={() => <AboutPage title={this.state.about.title} /> }  />
                        <Route path="/contact" exact render={() => <ContactPage title={this.state.contact.title} /> }  />
                    </Container>
                </Router>
            </div>
        )
    }
}

export default Landing