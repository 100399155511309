import React, {Component} from 'react'
import Hero from '../components/Hero'
import Content from '../components/Content'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Axios from 'axios'
import Footer from '../components/Footer'

class ContactPage extends Component{
    constructor(props){
        super(props)
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null
        }
    }

    handleChange = (event) =>{
        console.log(event);

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    } 


    handleSubmit = (event) => {
        console.log("SENDING EMAIL")
        event.preventDefault();

        this.setState({
            disabled: true
        });
        console.log("SENDING EMAIL")
        const api = 'https://egpq497gu5.execute-api.us-east-2.amazonaws.com/staging';
        const data = { "name" : "Mike" };
        Axios.post(api, this.state)
        .then( res => {
            console.log(res.data)
            if(res.data.success){
              console.log("SENT EMAIL")  
              this.setState({
                disabled: false,
                emailSent: true
              });
            } else{
                console.log("SENT EMAIL BUT IN ELSE PART")  
                this.setState({
                    disabled: false,
                    //update this part by sending SUCCESS message
                    emailSent: true
                });
            }
        })
        .catch(err => {
            console.log("EXCEPTION OCCURRED")  
            console.log(err.response.data);
            this.setState({
                disabled: false,
                emailSent: false
            });
        });
    }

    render(){
        
        return(
            <div className="parent-css">
                <Hero title={this.props.title} />
                <Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label htmlFor="full-name">Full Name</Form.Label>
                            <Form.Control id="full-name" name="name" type="text" value={this.state.name} onChange={this.handleChange}></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange}></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="message">Message</Form.Label>
                            <Form.Control id="message" name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange}></Form.Control>
                        </Form.Group>

                        <Button className="d-inline-block" variant="primary" type="submit" disabled={this.state.disabled}>
                            Send
                        </Button>

                    </Form>
                    
                    {this.state.emailSent === true && <p className="d-inline success-msg">Email Sent</p>}
                    {this.state.emailSent === false && <p className="d-inline err-msg">Email Not Sent</p>}
                </Content>
                <Footer />
            </div>
        )
    }
}

export default ContactPage