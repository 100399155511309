import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel';
import algo from '../images/uncc.JPG';
import travel from '../images/travel.JPG';
import cook from '../images/cook.JPG';
import piano from '../images/piano.JPG';
import explore from '../images/explore.JPG';
import chess from '../images/chess.JPG';
import drive from '../images/drive.JPG';

function SlidingCarousal(){
    const styleObj = {
        fontSize: 14,
        textAlign: "center",
        paddingLeft: "35%",
        width: "65%",
    }
    return(
        <div style={styleObj}>
            <Carousel showArrows={true} autoPlay>
                <div>
                    <img src={travel} />
                    <p className="legend">“Live with no excuses and travel with no regrets” ~ Oscar Wilde</p>
                </div>
                <div>
                    <img src={cook} />
                    <p className="legend">"Cooking today is young man's game" ~Gordon Ramsay</p>
                </div>
                <div>
                    <img src={piano} />
                    <p className="legend">“Without music, life would be a mistake” ― Friedrich Nietzsche</p>
                </div>
                <div>
                    <img src={explore} />
                    <p className="legend">“NOT ALL THOSE WHO WANDER ARE LOST.” – J.R.R. TOLKIEN</p>
                </div>
                <div>
                    <img src={chess} />
                    <p className="legend">“Chess is the gymnasium of the mind.” – Blaise Pasca</p>
                </div>
                <div>
                    <img src={drive} />
                    <p className="legend">Sometimes I chill by going on a long drive. Wanna Join!!</p>
                </div>
            </Carousel>
            </div>
            
    );
}


export default SlidingCarousal