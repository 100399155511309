import React from 'react'
import Hero from '../components/Hero'
import Carousal from '../components/Carousal'
import Download from '../components/Download'
import SocialFollow from '../components/SocialFollow'
import Footer from '../components/Footer'

function HomePage(props){
    return(
        <div class="parent-css">  
            <Hero title={props.title} subTitle={props.subTitle} text={props.text}/>
            <Carousal />
            <Download />
            <Footer />
        </div>
    )
}

export default HomePage