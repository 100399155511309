import React, {Component} from 'react'
import GitHubLogin from 'react-github-login'

class Github extends Component{

    state = {
        isLoggedIn: false,
        userID: '',
        name: '',
        email: '',
        picture: ''
    }

    onSuccess = response => {
        console.log(response)
    }

    onFailure = response => console.error(response);

    render(){
        let gitContent;

        if(this.state.isLoggedIn){
            gitContent = (
                <div style={{
                    width: '400px',
                    margin: 'auto',
                    background: '#f4f4f4',
                    padding: '20px'
                }}>
                    <img src={this.state.picture} alt={this.state.name} />
                    <h2>Welcome: {this.state.name} / {this.state.email}</h2>

                </div>
            )
        } else{
            gitContent= (<GitHubLogin 
                clientId="2ebe3b2d423eb13ab35e"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}/>)
        }

        return (
            <div>
                    {gitContent}
            </div>

        )
    }
}

export default Github;