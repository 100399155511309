import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import uncc from '../images/uncc.JPG';
import cusat from '../images/cusat.JPG';
import ReactPlayer from "react-player";

function SlidingPaneContent(props) {
  const styleObj = {
    fontSize: 14,
    color: "green",
    textAlign: "center",
    paddingTop: "50px",
}

const desc = {
  fontSize: 20,
  textAlign: "left",
}

const topPadding = {
  paddingTop: "50px",
}
  if (props.title === "Education") {
    return (
      <div className="education-slide" >
        <p>
          <h1 className="d-flex justify-content-center"><strong>EDUCATION</strong></h1>
        </p>
        <Container className="margin-top:100px" fluid={true} >
          <Row>
            <Col style={styleObj}>
             <img src={uncc} alt="University of North Carolina at Charlotte" />
              <p>
                <h1><strong>MS Computer Science </strong></h1>
              </p>
              <p><h2><strong>University of North Carolina At Charlotte </strong></h2></p>
              <p><h3> Charlotte Charlotte, NC, 2016- 2017 </h3></p>
            </Col>
            <Col style={styleObj}>
            <img src={cusat} alt="University of North Carolina at Charlotte" />
            <p></p>
             <p></p>
              <p>
              <h1><strong>B.TECH Computer Science and Engineering </strong></h1>
              </p>
              <p><h2><strong> Cochin University of Science And Technology </strong></h2></p>
              <p><h3> Kerala,India, 2009-2013 </h3></p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if(props.title === "Academic Projects"){
    return (<div className="academic_slide">
             <p>
              <h1 className="d-flex justify-content-center"><strong>Academic Projects</strong></h1>
              <h2>LetsRide-Ride Sharing Web Application</h2>
              <p><h4>University of North Carolina at Charlotte (Jan 2016 – May 2016)</h4></p>
              <p style={desc}>
                A Ride sharing web application on MVC framework using JAVA,JSP,Servlets and Oracle RDBMS.
                Design patterns used such as Observer pattern, Singleton pattern.
              </p> 

              <h2 style={topPadding}>Impulse Buying Control Mobile Application </h2>
              <p><h4>Hackathon HackNC at University of North Carolina at Chapel Hill (Aug 2016)</h4></p>
              <p style={desc}>
                Designed an android application, APIs and a Chrome Extension for Major Hacking League at 
                HackNC hackathon. The application takes the expenses details from Capital One API and it calculates the budget of the registered user. 
              </p>

              <h2 style={topPadding}>Image Classification using Machine Learning</h2>
              <p><h4>University of North Carolina at Charlotte Aug 2017 – Dec 2017</h4></p>
              <p style={desc}>
                Analyzed multiple algorithms for the purpose of image classification, including Naïve Bayes,
                K-Nearest Neighbors with/and Support Vector Machines.  
              </p>

              <h2 style={topPadding}>Social Mobility: Android Mobile Application</h2>
              <p><h4>DSI Hackathon Initiative at University of North Carolina at Charlotte Aug 2017 – Dec 2017</h4></p>
              <p style={desc}>
                The application is for the students and teachers which brings out the transparency of grades and achievements among students and teachers.
              </p>     
            </p>

      </div>);
  } else if(props.title === "Professional Projects"){
    return (
        <div className="project_slide">
          <h1 className="d-flex justify-content-center"><strong>Professional Projects</strong></h1>
          <h2>Cerner Corporation | Software Engineer | Kansas City, MO</h2>
          <ul style={desc}>
            <li>
              <p>
              Developed system to ensure accuracy and better patient-doctor communication by managing infusion and  
              medication drug flow using Java, RCP, Spring Boot and Mockito, with access in 1000+ hospitals globally.
              </p>
            </li>
            <li>
              <p>
                Created 10 versions of app releases using Electric Commander and improved process with Jenkins CI/CD to  reduce release process time by almost 50%.
              </p>
            </li>
            <li>
              <p>
                Analyzed security vulnerabilities in code using Fortify SSC which improved code quality and better security  from SQLInjection and other malpractices. 
              </p>
            </li>
            <li>
              <p>
                Optimized device search, device-drug association, and patient detail search, 
                by reducing database and batch  calls, and adding event listeners and caching using Google's Guava API, improving performance by 50%.  
              </p>
            </li>
            <li>
              <p>
                Migrated medical workflows services from Restlet to microservices architecture using Java and Spring Boot,  
                providing ability to develop and test multiple services independently; migrated repositories from SVN to Git.  
              </p>
            </li>
            <li>
              <p>
                Developed project in Java using design patterns like Singleton, Factory Pattern and Builder Pattern
                for better  maintenance of code and re-usability and aptly worked on pull requests and code reviews using Crucible.  
              </p>
            </li>           
          </ul>

          <h2 style={topPadding}>Deloitte | Software Engineer| Nashville, TN</h2>
          <ul style={desc}>
            <li>
              <p>
                Designed and developed business requirements and built unit test cases maintaining more than 90% code  coverage using Java and Spring MVC framework. 
              </p>
            </li>
            <li>
              <p>
                Analyzed, designed, and prepared programming specifications including coding, testing and debugging  programs/applications ​using Mockito and Jira. 
              </p>
            </li>          
          </ul>

          <h2 style={topPadding}>Outcomes Inc | Software Engineer | Ashburn, VA</h2>
          <ul style={desc}>
            <li>
              <p>
                Created backend RESTful API using Java, Spring, and MongoDB, following Agile methodology, which provided  real time location details of online users.  
              </p>
            </li>
            <li>
              <p>
                Built technical design documents, including use case diagram, data model, and workflow for hybrid mobile  application using PhoneGap. 
              </p>
            </li>
            <li>
              <p>
                Developed cross site, iOS and Android mobile platforms, integrating app into AWS services.  
              </p>
            </li>
            <li>
              <p>
                Leveraged Maven build tool and integrated JSON Web Token (JWT) authentication mechanism for security.  
              </p>
            </li>             
          </ul>

          <h2 style={topPadding}>TCS | Software Engineer | New Delhi, India</h2>
          <ul style={desc}>
            <li>
              <p>
              M​onitored production environment code 
              for ECommerce web application based on Java, Spring, and Oracle  RDBMS which helped clients to get fast resolution of production defects and issues.   
              </p>
            </li>
            <li>
              <p>
                Performed server network monitoring using Unix commands and AIX OS installation on servers; wrote UNIX  scripts to automate server health check and network monitoring
              </p>
            </li>           
          </ul>
        </div>
    );
  } else if(props.title === "Articles"){
    return (
      <div>
        <iframe className='iframe'
          src="https://video.eko.com/v/M73llg/" allowFullScreen
        >See this:</iframe>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=sDYljjwAJbc"
        >See this:</ReactPlayer>
      </div>
    )
  }
}

export default SlidingPaneContent;
