import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SocialFollow from './SocialFollow'

function Hero(props){
    return(
        <Jumbotron className="bg-transparent jumbotron-fluid p-0">
            <Container fluid={true}>
                <Row className="justify-content-center py-5">
                    <Col md={8} sm={12}>
                        { props.title && <h4 className="display-3 font-weight-bolder">{props.title} </h4> } 
                        { props.subTitle && <h4 className="display-4 font-weight-light">{props.subTitle}</h4> }
                        { props.text && <h5 className="lead font-weight-bolder">{props.text}</h5> }
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <SocialFollow /> 
                </Row>
            </Container>
        </Jumbotron>
    )
}

export default Hero